.chip {
    height: 50px;
    width: 50px;
    background-color: var(--icon_chip);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.social :hover {
    background-color: #00BC1D;
}

.chip > img {
    height: 25px;
}
.tech__icon {
    height: 100px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 10px;
}

.tech__icon > img {
    height: 80px;
}

.tech__icon :hover {
}

.app {
  background-color: var(--primary);
}

.page {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.page > section {
  scroll-snap-align: start;
}

/* SECTION 1*/ 

.section__1 {
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.section__1 > img:nth-child(1) {
  position: absolute;
  left: -20px;
  top: -80px;
}

.section__1 > img:nth-child(2) {
  position: absolute;
  left: 100px;
  top: 600px;

  animation: bounce 2.5s; 
  animation-direction: alternate; 
  animation-iteration-count: infinite; 
}

@keyframes bounce { 
  from { 
      transform: translate3d(0, 0, 0); 
  } 
  to { 
    transform: translate3d(0, 20px, 0); 
  } 
}

.section__1 > img:nth-child(3) {
  position: absolute;
  left: 80px;
  top: 100px;

  animation: bounce 4s; 
  animation-direction: alternate; 
  animation-iteration-count: infinite; 
}

@keyframes bounce { 
  from { 
      transform: translate3d(0, 0, 0); 
  } 
  to { 
      transform: translate3d(0, 30px, 0); 
  } 
}

.section__1 > img:nth-child(4) {
  position: absolute;
  left: 850px;
  top: 100px;

  animation: bounce 3s; 
  animation-direction: alternate; 
  animation-iteration-count: infinite; 
}

@keyframes bounce { 
  from { 
      transform: translate3d(0, 0, 0); 
  } 
  to { 
      transform: translate3d(0, 25px, 0); 
  } 
}

.intro {
  margin-left: 200px;
  z-index: 10;
}

.intro__one > span {
  color: var(--secondary);
  font-weight: 900;
  font-size: 60px;
  font-family: Gilroy-Black;
}

.intro__two > span {
  color: var(--tertiary);
  font-weight: 400;
  font-size: 18px;
  font-family: Gilroy-Regular;
}

.btn {
  margin-top: 20px;
}

/* SECTION 2*/

.section__2 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.social__exp {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.about__section {
  width: 40%;
  z-index: 10;
}

.about__section > p:nth-child(1) {
  justify-self: center;  
}

.section__2 > img:nth-child(1) {
  position: absolute;
  left: 50px;
}

.section__2 > img:nth-child(3) {
  position: absolute;
  right: -800px;
}

.about__heading {
  font-size: 45px;
  font-weight: 800;
  font-family: Gilroy-Black;
  color: var(--secondary);
  text-align: center;
}

.about__text {
  font-size: 18px;
  font-weight: 400;  
  font-family: Gilroy-Regular;
  color: var(--tertiary);
  margin-top: 80px;
}

.about__handle {
  font-size: 18px;
  font-weight: 600;  
  font-family: Gilroy-Medium;
  color: var(--tertiary);
}

.about__exp {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.about__exp > span:nth-child(1) {
  font-size: 80px;
  font-weight: 800;  
  font-family: Gilroy-Black;
  color: var(--tertiary);
}

.about__exp > span:nth-child(2) {
  font-size: 18px;
  font-weight: 400;  
  font-family: Gilroy-Regular;
  color: var(--tertiary);
  margin: 15px 0 0 15px;
}

.icons {
  margin-top: 10px;
  display: flex;
  
}

/* SECTION 3 */

.section__3 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.section__3 > img:nth-child(1) {
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -900px;
}

.section__3 > img:nth-child(3) {
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  right: -950px;
}

.tech__icons {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 800px;
}
button {
    height: 50px;
    width: 160px;
    border-radius: 4px;
    background-color: var(--cta);
    border-style: none;
    outline: none;
    font-size: 16px;
    font-weight: 800;
    font-family: Gilroy;
    color: var(--text);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    cursor: pointer;
}

button > img {
    height: 16px;
    margin-right: 10px;
}

a {
    text-decoration: none;
}
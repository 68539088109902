* {
  margin: 0;
  padding: 0;
  --primary: #343233;
  --secondary:#FFEED4;
  --tertiary: #D1BFB1;
  --cta: #FFD152;
  --text: #343233;
  --icon_chip: #272727;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Gilroy-Regular;
  src: url(/src/font/Gilroy-Regular.woff);
}

@font-face {
  font-family: Gilroy-Black;
  src: url(/src/font/Gilroy-Black.woff);
}

@font-face {
  font-family: Gilroy-BlackItalic;
  src: url(/src/font/Gilroy-BlackItalic.woff);
}

@font-face {
  font-family: Gilroy-Bold;
  src: url(/src/font/Gilroy-Bold.woff);
}

@font-face {
  font-family: Gilroy-BoldItalic;
  src: url(/src/font/Gilroy-BoldItalic.woff);
}

@font-face {
  font-family: Gilroy-Extrabold;
  src: url(/src/font/Gilroy-Extrabold.woff);
}

@font-face {
  font-family: Gilroy-ExtraboldItalic;
  src: url(/src/font/Gilroy-ExtraboldItalic.woff);
}

@font-face {
  font-family: Gilroy-Heavy;
  src: url(/src/font/Gilroy-Heavy.woff);
}

@font-face {
  font-family: Gilroy-HeavyItalic;
  src: url(/src/font/Gilroy-HeavyItalic.woff);
}

@font-face {
  font-family: Gilroy-Light;
  src: url(/src/font/Gilroy-Light.woff);
}

@font-face {
  font-family: Gilroy-LightItalic;
  src: url(/src/font/Gilroy-LightItalic.woff);
}

@font-face {
  font-family: Gilroy-Medium;
  src: url(/src/font/Gilroy-Medium.woff);
}

@font-face {
  font-family: Gilroy-MediumItalic;
  src: url(/src/font/Gilroy-MediumItalic.woff);
}

@font-face {
  font-family: Gilroy-RegularItalic;
  src: url(/src/font/Gilroy-RegularItalic.woff);
}

@font-face {
  font-family: Gilroy-Semibold;
  src: url(/src/font/Gilroy-Semibold.woff);
}

@font-face {
  font-family: Gilroy-SemiboldItalic;
  src: url(/src/font/Gilroy-SemiboldItalic.woff);
}

@font-face {
  font-family: Gilroy-Thin;
  src: url(/src/font/Gilroy-Thin.woff);
}

@font-face {
  font-family: Gilroy-ThinItalic;
  src: url(/src/font/Gilroy-ThinItalic.woff);
}

@font-face {
  font-family: Gilroy-UltraLight;
  src: url(/src/font/Gilroy-UltraLight.woff);
}

.hero-logo-circles {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 600px;
    overflow: hidden;
}

.hero-logo-circle {
    position: absolute;
}

.hero-logo-circle:nth-child(1) {
}

.hero-logo-circle:nth-child(2) {
    -webkit-animation: spin 120s linear infinite;
    animation: spin 120s linear infinite;
}

.hero-logo-circle:nth-child(40) {
    -webkit-animation: spin 110s linear infinite;
    animation: spin 110s linear infinite;
}

.hero-logo-circle:nth-child(4) {
    -webkit-animation: spin 90s linear infinite;
    animation: spin 90s linear infinite;
}

.hero-logo-circle:nth-child(5) {
    -webkit-animation: spin 80s linear infinite;
    animation: spin 80s linear infinite;
}

.hero-logo-circle:nth-child(6) {
    -webkit-animation: spin 60s linear infinite;
    animation: spin 60s linear infinite;
}

.hero-logo-circle:nth-child(7) {
    -webkit-animation: spin 50s linear infinite;
    animation: spin 50s linear infinite;
}

.hero-logo-circle:nth-child(8) {
    -webkit-animation: spin 40s linear infinite;
    animation: spin 40s linear infinite;
}

.hero-logo-circle:nth-child(9) {
    -webkit-animation: spin 30s linear infinite;
    animation: spin 30s linear infinite;
}

.hero-logo-circle:nth-child(10) {
    -webkit-animation: spin 60s linear infinite;
    animation: spin 60s linear infinite;
}

.hero-logo-circle:nth-child(11) {
    -webkit-animation: spin 80s linear infinite;
    animation: spin 80s linear infinite;
}

@keyframes spin {
    100% { -webkit-transform: rotate(1turn); }
}